import React, { useEffect, useState } from "react";

// Ant Design components
import { Row, Col, Tooltip } from "antd";

// Prop-types for type checking
import PropTypes from "prop-types";

// Localization components
import { Translate, withLocalize } from "react-localize-redux";

// Redux related imports
import { connect } from "react-redux";
import {
  initialize,
  Field,
  reduxForm,
  formValueSelector,
  change,
  arrayRemove,
} from "redux-form";

// Custom components and assets
import DropdownIcon from "../../../assets/icons/dropdown_blue.svg";
import EmptyStateIcon from "../../../assets/icons/empty_state.svg";
import {
  GetOpenPO,
  CreateMapNeeds,
} from "../../../infra/requests/InternalPORequests";
import AlertService from "../../../shared/components/alert/AlertService";
import BaseButton from "../../../shared/components/buttons/BaseButton";
import NumberIncrementInput from "../../../shared/components/inputs/NumberIncrementInput";
import BaseLoading from "../../../shared/components/loading/BaseLoading";
import BaseTable from "../../../shared/components/table/BaseTable";
import RoundCurrency from "../../../shared/logic/numbers/RoundCurrency";
import ActiveTranslation from "../../../shared/logic/translations/ActiveTranslation";
import { ToggleImage } from "../../../shared/styles/BasicStyles";
import {
  POBaseTitle,
  POOrderNumber,
  POBaseContainer,
  POHeaderCollapsable,
  POLeftContainer,
  POTableContainer,
  POTableFooter,
  POTotalSection,
  FooterLine,
  WarningTooltip,
  EmptyContainer,
  EmptyMessage,
  EmptyIcon,
  MessageTrigger,
  Message,
} from "../components/POStyles";
import OpenHeader from "./OpenHeader";
import OpenPOFilters from "./OpenPOFilters";
import { BackwardOutlined } from "@ant-design/icons";
import { RevertOrderPODraft } from "../../../infra/requests/SupplierOrdersRequests";

// Function to determine if a Purchase Order (PO) should be shown based on filters
const ShowPO = (po, filters) => {
  const convertedOrderB2bid = String(po.orderB2bid);
  const convertedProductId = String(po.productId);
  const convertedClient = String(po.orderB2b.b2bclient.b2bclientId);

  if (
    (filters.orderB2bid &&
      filters.orderB2bid !== "" &&
      convertedOrderB2bid !== filters.orderB2bid) ||
    (filters.product &&
      filters.product !== "" &&
      convertedProductId !== filters.product) ||
    (filters.client &&
      filters.client !== "" &&
      convertedClient !== filters.client)
  ) {

    return false;
  }

  return true;
};

// Function to calculate the total for a given PO
const calculatePOTotal = (po) => {
  let qty = 0;
  let price = 0;
  if (po && Array.isArray(po.orderPoitem)) {
    po.orderPoitem.forEach((item) => {
      qty += parseInt(item.quantity, 10) || 0;
      price += parseFloat(
        item.unitPrice * item.quantity * (item.exchangeRateUsed || 1)
      );
    });
  }

  return (
    <POTotalSection>
      <FooterLine>
        <Translate id="TOTAL_ORDER_QTY" />: <span>{parseInt(qty, 10)}</span>
      </FooterLine>
      <FooterLine bold>
        <Translate id="TOTAL_PRICE" />: <span>{RoundCurrency(price)}€</span>
      </FooterLine>
      {parseInt(po?.prodcut?.moq || 0, 10) > parseInt(qty, 10) && (
        <Tooltip placement="top" title={<Translate id="ALERT_MOQ" />}>
          <WarningTooltip>!</WarningTooltip>
        </Tooltip>
      )}
    </POTotalSection>
  );
};

// Main component for the Open PO tab
const OpenPOTab = ({ POList, dispatch, router, translate }) => {
  // State for loading indicator and filters
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    orderB2bid: "",
    client: "",
    product: "",
  });
  const [flag, setflag] = useState(false)

  // Fetch POs on component mount
  useEffect(() => {
    async function fetchPOs() {
      const { data } = await GetOpenPO();
      dispatch(
        initialize("manage_internal_po", {
          POList: (data?.items || []).map((po) => ({
            ...po,
            orderPoitem: po.orderPoitem.map((item) => ({
              ...item,
              total: item.quantity,
            })),
          })),
        })
      );
      setLoading(false);
      setflag(false)
    }
    fetchPOs();
  }, []); // Empty dependency array ensures this effect runs only on mount



  // Function to toggle the 'open' property of a PO
  const openPO = (index) => {
    dispatch(
      change("manage_internal_po", `POList[${index}].open`, !POList[index].open)
    );
  };

  // Function to render columns for the BaseTable
  const renderColumns = (POIndex, PO) => {
    const columns = [
      {
        type: "text",
        dataIndex: "sku",
        title: <Translate id="REF_CODE" />,
      },
      {
        type: "text",
        dataIndex: "productCombination",
        title: <Translate id="DESCRIPTION" />,
        render: (value) => (
          <ActiveTranslation
            value={value?.product?.productTranslation}
            tag="name"
          />
        ),
      },
      {
        type: "text",
        dataIndex: "productCombination",
        title: <Translate id="COLOR" />,
        render: (value) => (
          <ActiveTranslation
            value={value?.color?.colorTranslation}
            tag="name"
          />
        ),
      },
      {
        type: "text",
        dataIndex: "productCombination",
        title: <Translate id="SIZE" />,
        render: (value) => value?.size?.number,
      },
      {
        type: "text",
        dataIndex: "productCombination",
        title: <Translate id="UM" />,
        render: (value) => value?.product?.measureUnit?.code,
      },
      {
        type: "text",
        dataIndex: "quantity",
        title: <Translate id="C_ORDER_QTY" />,
      },
      {
        type: "text",
        dataIndex: "productCombination",
        title: <Translate id="STOCK_QTY" />,
        render: (value) => value?.stock,
      },
      {
        type: "text",
        title: <Translate id="RESERVE" />,
        render: (text, row, index) => (
          <Field
            component={NumberIncrementInput}
            name={`POList[${POIndex}].orderPoitem[${index}].reserve`}
            placeholder=""
            afterChange={(value) => {
              dispatch(
                change(
                  "manage_internal_po",
                  `POList[${POIndex}].orderPoitem[${index}].quantity`,
                  row.total - value > 0 ? row.total - value : 0
                )
              );
            }}
            min={0}
            max={row.total}
          />
        ),
      },
      {
        type: "text",
        title: <Translate id="PO_QTY" />,
        render: (text, row, index) => (
          <Field
            component={NumberIncrementInput}
            name={`POList[${POIndex}].orderPoitem[${index}].quantity`}
            placeholder=""
            min="0"
          />
        ),
      },
    ];
    const currency = PO.orderPoitem.find((i) => i.currencyId !== 1);

    if (currency) {
      columns.push({
        type: "text",
        title: <Translate id="UNIT_PRICE_EUR" />,
        render: (data) =>
          data.currency?.currencyId !== 1
            ? `${RoundCurrency(data.unitPrice * (data.exchangeRateUsed || 1))}€`
            : `${RoundCurrency(data.unitPrice)}€`,
      });
      columns.push({
        type: "text",
        title: <Translate id="PRICE_EUROS" />,
        render: (data) =>
          data.currency?.currencyId !== 1
            ? `${RoundCurrency(
              data.unitPrice * data.quantity * (data.exchangeRateUsed || 1)
            )}€`
            : `${RoundCurrency(data.unitPrice * data.quantity)}€`,
      });
      columns.push({
        type: "text",
        title: <Translate id="UNIT_PR" />,
        render: (data) =>
          data.currency?.currencyId !== 1
            ? `${RoundCurrency(data.unitPrice)} ${data?.currency?.code}`
            : "-",
      });
      columns.push({
        type: "text",
        title: <Translate id="PRICE" />,
        render: (data) =>
          data.currency?.currencyId !== 1
            ? `${RoundCurrency(data.unitPrice * data.quantity)} ${data?.currency?.code
            }`
            : "-",
      });
    } else {
      columns.push({
        type: "text",
        dataIndex: "unitPrice",
        title: <Translate id="UNIT_PRICE_EUR" />,
        render: (value) => RoundCurrency(value),
      });
      columns.push({
        type: "text",
        title: <Translate id="PRICE_EUROS" />,
        render: (text, row) => RoundCurrency(row.unitPrice * row.quantity),
      });
    }

    return columns;
  };

  // Function to create map needs for a given PO
  const createMapNeeds = async (po, index) => {
    dispatch(change("manage_internal_po", `POList[${index}].loading`, true));

    const payload = {
      OrderPoid: po.orderPoid,
      OrderPoitem: po.orderPoitem.map((item) => ({
        OrderPoitemId: item.orderPoitemId,
        Quantity: item.quantity,
        Reserve: item.reserve,
      })),
    };

    const { success } = await CreateMapNeeds(po.orderPoid, payload);

    if (success) {
      const { data } = await GetOpenPO();
      dispatch(
        initialize("manage_internal_po", {
          POList: (data?.items || []).map((po) => ({
            ...po,
            orderPoitem: po.orderPoitem.map((item) => ({
              ...item,
              total: item.quantity,
            })),
          })),
        })
      );
      if (POList.length <= 1) {
        return router.history.push("/suppliers#process");
      }
    } else {
      dispatch(change("manage_internal_po", `POList[${index}].loading`, false));
    }
  };

  const revertPO = (po) => {
    AlertService.confirm(
      "Warning",
      `Do you really want to revert the B2B order status and revert the creation of PO’s?`,
      async () => {
        const { success } = await RevertOrderPODraft(po.orderPoid)
        if (success) {
          const { data } = await GetOpenPO();
          dispatch(
            initialize("manage_internal_po", {
              POList: (data?.items || []).map((po) => ({
                ...po,
                orderPoitem: po.orderPoitem.map((item) => ({
                  ...item,
                  total: item.quantity,
                })),
              })),
            })
          );
        }
      }
    );
  }

  // Function to validate MOQ and proceed with creating map needs
  const validateMOQ = (po, index) => {
    let qty = 0;
    if (po && Array.isArray(po.orderPoitem)) {
      po.orderPoitem.forEach((item) => {
        qty += parseInt(item.quantity, 10) || 0;
      });
    }
    const requirements =
      parseInt(po?.product?.moq || 0, 10) < parseInt(qty, 10);
    if (requirements) return createMapNeeds(po, index);
    return AlertService.confirm(
      translate("TOTAL_ORDER_QTY_LESS_THAN_MOQ"),
      translate("CONTINUE_CONFIRM"),
      () => createMapNeeds(po, index)
    );
  };

  // If still loading, display loading indicator
  if (loading) return <BaseLoading margin={100} />;

  // If no POs, display empty state
  if (POList.length === 0) {
    return (
      <Row>
        <Col xs={24}>
          <EmptyContainer>
            <EmptyMessage>
              <Message>
                <Translate id="OPEN_PO_EMPTY_DESC1" />
              </Message>
              <MessageTrigger
                onClick={() => router.history.push("/orders#b2b")}
              >
                <Translate id="ORDERS_B2B" />
              </MessageTrigger>
              <Message>
                <Translate id="OPEN_PO_EMPTY_DESC2" />
              </Message>
            </EmptyMessage>
            <EmptyIcon src={EmptyStateIcon} />
          </EmptyContainer>
        </Col>
      </Row>
    );
  }

  // Render the main component content
  return (
    <div>
      <Row>
        <Col xs={24}>
          {/* Filter component */}
          <OpenPOFilters
            POList={POList}
            filters={filters}
            setFilters={setFilters}
          />
        </Col>
      </Row>

      {/* Display POs based on filters */}
      {POList.map((PO, index) => {
        if (ShowPO(PO, filters)) {
          return (
            <Row key={index}>
              <Col xs={24}>
                {/* PO Title and Header */}
                <POBaseTitle>
                  <Translate id="INTERNAL_PO" />
                  <POOrderNumber>
                    - <Translate id="ORDER" /> #{PO?.orderB2b?.number}
                  </POOrderNumber>
                </POBaseTitle>

                {/* Main PO Container */}
                <POBaseContainer>
                  {/* Collapsable Header */}


                  <POHeaderCollapsable id="caramelo" onClick={() => openPO(index)}>
                    <POLeftContainer>
                      <ToggleImage src={DropdownIcon} $open={PO.open} />
                      <OpenHeader PO={PO} />
                    </POLeftContainer>
                  </POHeaderCollapsable>


                  {/* Table Container */}
                  <POTableContainer open={PO.open}>
                    {/* Main PO Table */}
                    <BaseTable
                      rowKey="orderPoitemId"
                      columns={renderColumns(index, PO)}
                      datasource={PO.orderPoitem}
                      pagination={{ render: false }}
                    />

                    {/* Table Footer with Total and Button */}
                    <POTableFooter style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BaseButton
                          auto
                          type="secondary"
                          loading={PO.loading}
                          onClick={() => revertPO(PO)}
                        >
                          <BackwardOutlined /> Revert PO creation
                        </BaseButton>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {calculatePOTotal(PO)}
                        <BaseButton
                          auto
                          type="primary"
                          loading={PO.loading}
                          onClick={() => validateMOQ(PO)}
                        >
                          <Translate id="SEND_PROCESS" />
                        </BaseButton>
                      </div>
                    </POTableFooter>

                  </POTableContainer>
                </POBaseContainer>
              </Col>
            </Row>
          );
        }
        return null;
      })}
    </div>
  );
};

// Prop types for type-checking
OpenPOTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  POList: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
};

// Default props
OpenPOTab.defaultProps = {};

// Redux Form higher-order component wrapping
const myComponent = reduxForm({
  form: "manage_internal_po",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(OpenPOTab);

// Selector for extracting form values from Redux state
const selector = formValueSelector("manage_internal_po");

// Map state to props for connecting to Redux store
const mapStateToProps = (state) => ({
  POList: selector(state, "POList") || [],
  initialValues: { POList: [] },
});

// Connect the component to Redux store and localize
export default withLocalize(connect(mapStateToProps)(myComponent));
