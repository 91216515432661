import { Steps } from 'antd'
import styled from 'styled-components'

import {
  BorderColor,
  PrimaryColor,
  TableTextColor,
  TableHeaderBackground
} from '../../styles/_colors'
import {
  MediumFontWeight,
  Subtitle1Text,
  Subtitle2Text
} from '../../styles/_texts'

const { Step } = Steps

export const CustomDotContainer = styled.button`
  width: 40px;
  height: 40px;
  background: ${({ $active }) => ($active ? PrimaryColor : BorderColor)};
  border-radius: 100%;
  color: #fff;
  font-size: ${Subtitle1Text};
  font-weight: ${MediumFontWeight};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;

  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const StyledSteps = styled(Steps)`
  width: 100%;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  background-color: ${TableHeaderBackground};
  border-radius: 4px;
  border: 1px solid ${BorderColor};
`

export const StyledStep = styled(Step)`
  cursor: pointer;

  &&& {
    .ant-steps-item-icon, .ant-steps-item-process .ant-steps-item-icon {
      width: 40px;
      height: 40px;
      margin-left: 40px;
      margin-top: 10px;

      .ant-steps-icon {
        top: -8px;
      }
    }

    .ant-steps-item-tail {
      top: 20px;
      margin: 0 0 0 50px;
    }

    .ant-steps-item-content {
      width: 116px;
      .ant-steps-item-title {
        color: ${({ $active }) => ($active ? PrimaryColor : TableTextColor)};
        font-size: ${Subtitle2Text};
      }
    }
  }
`
