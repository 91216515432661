import React, { useState, useEffect } from "react";
import { BarChartOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import {
  VictoryChart,
  VictoryTheme,
  VictoryBar,
  VictoryTooltip,
  VictoryGroup,
  VictoryLabel,
  VictoryAxis,
  VictoryContainer,
} from "victory";

import { StyledFlex } from "../../../shared/styles/BasicStyles";
import {
  EmptyDashboardMessage,
  StyledVictoryChartWrapper,
  ChartLoading,
} from "../DashboardStyles";
import LegendItem from "./LegendItem";

const DoubleAxisGraphic = ({ info, loading }) => {
  const [maxYValue, setMaxYValue] = useState(0);
  const [maxYValue2, setMaxYValue2] = useState(0);
  const [count, setCount] = useState(0)

  useEffect(() => {
    let max = Number.MIN_SAFE_INTEGER;
    let max2 = Number.MIN_SAFE_INTEGER;

    info.items.forEach(item => {
      const value2 = parseFloat(item[info.options.y[0].key]);
      const value3 = parseFloat(item[info.options.y[1].key]);

      if (!isNaN(value2) && value2 > max) {
        max = value2;
      }
      if (!isNaN(value3) && value3 > max2) {
        max2 = value3;
      }
    });

    setMaxYValue(max);
    setMaxYValue2(max2);
  }, [info]);

  const calculateTickLegendValue = (() => {
    return (t, maxima) => {
      let value = t * maxima;
      let value2

      if (value < 0.0001) {
        return ''
      } else if (maxYValue <= 5) {
        value = t * 5;
        return Math.trunc(value);  // Arredonda para o inteiro mais próximo
      }

      if (value >= 1000000) {
        value2 = value / 1000000
        return `${value2.toFixed(1)}M`; // Arredonda para o inteiro mais próximo
      } else if (value >= 1000) {
        value2 = value / 1000
        return `${value2.toFixed(1)}K`; // Arredonda para o inteiro mais próximo
      } else {
        return Math.trunc(value);
      }
    };
  })();

  // Helper function to check if all y-values are zero
  const areAllYValuesZero = (items, yOptions) => {
    return items.every(item =>
      yOptions.every(yOption => item[yOption.key] === 0)
    );
  };

  // Check if all y-values are zero
  if (areAllYValuesZero(info.items, info.options.y)) {

    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_DATA_AVAILABLE" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  // const CustomTooltip = (props) => {
  //   const { datum, x, y } = props;
  //   return (
  //     <foreignObject x={x + 10} y={y - 40} width="150" height="100">
  //       <div style={{
  //         background: '#0267B2',
  //         color: 'white',
  //         padding: '5px',
  //         borderRadius: '5px',
  //         fontSize: '12px'
  //       }}>
  //         <p><strong>Date:</strong> {datum[info.options.x.key]}</p>
  //         {info.options.y.map((label) => (
  //           <p key={label.key}><strong>{label.name}:</strong> {datum[label.key]}</p>
  //         ))}
  //       </div>
  //     </foreignObject>
  //   );
  // };

  const calculateTickLegendValue2 = (() => {
    return (t, maxima) => {
      let value = t * maxima;
      let value2

      if (value < 0.0001) {
        return ''
      } else if (maxYValue2 <= 5) {
        value = t * 5;

        return Math.trunc(value); // Arredonda para o inteiro mais próximo
      }

      if (value >= 1000000) {
        value2 = value / 1000000
        return `${value2.toFixed(1)}M`; // Arredonda para o inteiro mais próximo
      } else if (value >= 1000) {
        value2 = value / 1000
        return `${value2.toFixed(1)}K`; // Arredonda para o inteiro mais próximo
      } else {
        return Math.trunc(value);
      }
    };
  })();



  if (info.options.empty) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_AVAILABLE_INFO" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.x) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_XAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  const shouldRotateText = (key, items) =>
    items.some((x) => x[key].length > 12) && items.length > 6;

  const setTickLabelsText = (key, items) => {
    if (shouldRotateText(key, items)) {
      return { tickLabels: { angle: 20 } };
    }
    return null;
  };

  function getStyles() {

    const BLUE_COLOR = "#00a3de";
    const RED_COLOR = "#7c270b";

    return {
      labelOne: {
        fill: BLUE_COLOR,
        fontFamily: "inherit",
        fontSize: 12,
        fontStyle: "italic",
      },
      labelTwo: {
        textAnchor: "end",
        fill: RED_COLOR,
        fontFamily: "inherit",
        fontSize: 12,
        fontStyle: "italic",
      },
    };
  }

  const styles = getStyles();

  const maxima = info.options.y.map((option) =>
    Math.max(...info.items.map((d) => d[option.key])),
  );

  return (
    <>
      <StyledFlex $direction="row" $justify="initial" $top="12">
        {info.options.y.map((label) => (
          <LegendItem key={label.key} color={label.color} name={label.name} />
        ))}
      </StyledFlex>

      <StyledVictoryChartWrapper style={{ padding: "0px 10px" }}>
        {loading && (
          <ChartLoading>
            <Spin />
          </ChartLoading>
        )}
        <VictoryChart
          theme={VictoryTheme.material}
          width={550}
          domainPadding={50}
          containerComponent={
            <VictoryContainer
              style={{
                pointerEvents: "none",
                userSelect: "auto",
                touchAction: "auto",
              }}
            />
          }
        >
          <VictoryAxis
            fixLabelOverlap={true}
            scale="time"
            tickValues={info.items.map((item) => item[info.options.x.key])}
            style={setTickLabelsText(info.options.x.key, info.items)}
            tickLabelComponent={
              <VictoryLabel
                {...(shouldRotateText(info.options.x.key, info.items) && {
                  textAnchor: "start",
                  dy: -6,
                  dx: -6,
                })}
                text={({ index }) => {
                  const value = info.items[index][info.options.x.key];
                  if (info.options.x.key === "x_ReferenceCode") {
                    return value;
                  } else {
                    if (info.options.x.key === "x_ProductName") {
                      return value;
                    }
                  }
                  if (moment(value, "MM/YYYY").isValid()) {
                    return moment(value, "MM/YYYY")
                      .format("MMM YYYY")
                      .split(" ");
                  }
                  return value.length > 10 ? `${value.slice(0, 10)}...` : value;
                }}
                style={[
                  { fill: "#231F20", fontSize: 14 },
                  {
                    fill: "#231F20",
                    opacity: "50%",
                    fontSize: 14
                  },
                ]}
              />
            }
          />

          {info.options.y.map((label, index) => (
            <VictoryLabel
              key={label.key}
              x={22 + index * 505}
              y={35}
              style={{
                ...styles.labelTwo,
                fill: "rgba(35, 31, 32, 0.5)",
                fontSize: 13,
                textAnchor: "middle",
                textAlign: "center",
              }}
              text={label.name}
            />
          ))}

          <VictoryAxis
            tickCount={maxYValue == 2 || maxYValue == 1 ? 2 : (maxYValue == 0 ? 1 : 5)} // Adjust the number of ticks as needed
            dependentAxis
            tickFormat={(t) => calculateTickLegendValue(t, maxima[0])}
            tickLabelComponent={<VictoryLabel dx={6} style={{ fontSize: 14 }} />}
          />

          <VictoryAxis
            tickCount={maxYValue2 == 2 ? 2 : (maxYValue2 == 0 ? 1 : 5)} // Adjust the number of ticks as needed
            dependentAxis
            offsetX={500}
            style={{
              ticks: { padding: -25 },
            }}
            tickFormat={(t) => calculateTickLegendValue2(t, maxima[1], true)}
            tickLabelComponent={
              <VictoryLabel dx={0} textAnchor={"start"} style={{ fontSize: 14 }} />
            }
          />

          <VictoryGroup offset={12}>
            {info.options.y.map((label, index) => (
              <VictoryBar
                key={label.key}
                style={{ data: { fill: label.color, width: 12 } }}
                cornerRadius={{ topLeft: 1, topRight: 1 }}
                x={info.options.x.key}
                y={(datum) => {

                  if (label.key === info.options.y[1].key && maxYValue2 <= 5) {
                    return datum[label.key] / 5;
                  }

                  if (label.key === info.options.y[0].key && maxYValue <= 5) {
                    return datum[label.key] / 5
                      ;
                  }

                  // Escala normal para valores maiores que 7
                  return datum[label.key] / maxima[index];
                }}
                barRatio={1}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                labelComponent={<VictoryTooltip />}
                labels={({ datum }) =>
                  `${datum[info.options.x.key]} - ${datum[label.key]}`
                }
                data={info.items}
              />
            ))}
          </VictoryGroup>
        </VictoryChart>
      </StyledVictoryChartWrapper>
    </>
  );
};

DoubleAxisGraphic.propTypes = {
  info: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

DoubleAxisGraphic.defaultProps = {
  loading: false,
};

export default DoubleAxisGraphic;